import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';
import qr from '../../../images/qr-demo-wadiki-cloud-qr-703R-silver.png';
import logo from '../../../images/logo_small_light.png';
import { motion } from 'framer-motion';

type IProps = {}

export const Page9 = (props: IProps) => {


  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />
    <div className='d-flex flex-column w-100 container'>
      <div className={'fs-big mb-5'}>
        Wir sind 24/7<br />
        erreichbar und<br />
        unsere Schilder sind<br />
        auch bei Dunkelheit<br />
        sichtbar<br />
      </div>

      <div className='d-flex mt-5'
           style={{ opacity: 0.8 }}
      >
        <div className='col-2'></div>
        <motion.div
          className='col-6 glow'
        >
          <h1 className={'fs-big mb-5'} style={{ fontSize: '2em' }}>Hallo!</h1>
          <div className='lh-1'>
            Das ist die Wadiki<br />
            Cloud. Scannen Sie,<br />
            um Spielplatzschäden<br />
            zu melden.
          </div>
        </motion.div>
        <div
          className='col-4 d-flex flex-column align-items-end'
        >
          <img
            alt={'Wadiki Cloud Logo'}
            style={{ opacity: 0.6, maxWidth: 50 }}
            src={logo} />
          <img
            alt={'Wadiki Cloud QR-Code'}
            style={{ opacity: 0.7 }}
            className='mt-5 w-100'
            src={qr} />
        </div>
      </div>

    </div>
    <div className='footer'>
      <div
        className='ms-auto wadiki-pill bg-blue text-center'
        style={{
          width: '100%',
          borderRadius: 0,
        }}
      >
        Zugang zur Wadiki Cloud
      </div>
    </div>
  </Sc.Wrapper>;
};
