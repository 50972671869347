import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';
import { motion } from 'framer-motion';

type IProps = {}
const speed = 0.3;
export const Page6 = (props: IProps) => {

  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />

    <div className='d-flex flex-column w-100 container'>
      <div className={'fs-big mb-5'}>
        Wadiki behebt alle<br />
        Mängel effizient,<br />
        rechtssicher und<br />
        nachhaltig<br />
        <br />
        <br />
        <br />
      </div>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: '5em' },
          visible: { opacity: 1, x: '3em' },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{
          type: 'easeIn',
          duration: speed,
          delay: speed * 1,
        }}
        className='ms-auto wadiki-pill bg-yellow mb-5'
        style={{ transform: 'translate(3em,0)' }}>
        Instandsetzung
      </motion.div>
      <div
        className='me-auto wadiki-pill bg-green mb-5 text-start'
        style={{
          transform: 'translate(-3em,0)',
          width: '80%',
        }}>
        Inspektion & Planung
      </div>

    </div>
    <div className='footer'>
      <div
        className='ms-auto wadiki-pill bg-blue text-center'
        style={{
          width: '100%',
          borderRadius: 0,
        }}
      >
        Zugang zur Wadiki Cloud
      </div>
    </div>
  </Sc.Wrapper>;
};
