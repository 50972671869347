import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import { motion } from 'framer-motion';


type IProps = {}


export const Page4 = (props: IProps) => {
  const items = [
    'Digitalisierung + Dienstleistung',
    'anders als gewöhnliche Bürgermelder',
    'Ticketbearbeitung erfolgt über Dienstleister zum Festpreis',
    'zentrale Plattform mit Schnittstellen zu allen relevanten Gewerke',
    '100% Transparent und Rechtssicher durch Archivierung aller Ereignisse',
    'Rechtskonforme Prüfungen / Dokumentation im Zuge der Betreuung',
    'Einbeziehung Bürger durch QR Melde System',
    'Modular erweiterbar (Wartung, Grünpflege, Mülleimer)',
  ];
  return <Sc.Wrapper>
    <div className='container p-5'>
      <div className='fs-big mb-5'>Idee</div>
      <div className='fs-4 mt-5'>
        <ul>
          {items.map((item, index) => (
            <motion.li
              variants={{
                hidden: { opacity: 0.2, y: 10 },
                visible: { opacity: 1, y: 0 },
              }}
              initial='hidden'
              whileInView='visible'
              transition={{
                type: 'spring',
                stiffness: 500,
                damping: 30,
                duration: 0.2,
                delay: index * 0.2 + 0.5,
              }}
              key={index}>
              {item}
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
    <BgLogoLayer theme={'light'} />
  </Sc.Wrapper>;
};