import styled from 'styled-components';


export const Wrapper = styled.div`

  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;


  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
  }


  .fs-big {
    font-size: 15vh;
    line-height: 1em;
    font-weight: 600;
  }

  .progress {
    z-index: 300;
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    height: 5px;
    background: #FFC700;
  }

  .page-container {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      flex: 1 auto;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

`;