import React from 'react';
import * as Sc from './styled';
import { Page1 } from '../Page1';
import { Page2 } from '../Page2';
import { Page3 } from '../Page3';
import { Page4 } from '../Page4';
import { Page5 } from '../Page5';
import { Page6 } from '../Page6';
import { Page7 } from '../Page7';
import { Page8 } from '../Page8';
import { Page9 } from '../Page9';
import { Page10 } from '../Page10';
import { motion } from 'framer-motion';

type IProps = {}

export const MobileStart = (props: IProps) => {

  const pages = [{
    content: <Page1 />,
  }, {
    content: <Page2 />,
  }, {
    content: <Page3 />,
  }, {
    content: <Page4 />,
  }, {
    content: <Page5 />,
  }, {
    content: <Page6 />,
  }, {
    content: <Page7 />,
  }, {
    content: <Page8 />,
  }, {
    content: <Page9 />,
  }, {
    content: <Page10 />,
  },
  ];

  return <Sc.Wrapper>
    <div className='d-flex flex-row'>

      {pages.map((item, index) => (
        <section
          key={index}
        >
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial='hidden'
            whileInView='visible'
            transition={{ duration: 0.70, delay: 0.15 }}
            className='page-container'>
            {item.content}
          </motion.div>
        </section>
      ))}
    </div>

  </Sc.Wrapper>;
};

/*
import { motion, useScroll, useSpring } from 'framer-motion';

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
    <motion.div className='progress' style={{ scaleX }}></motion.div>

 */