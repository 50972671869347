import React from 'react';
import '@fontsource-variable/fredoka';
import 'bootstrap';
import './styles/main.css';

import {PublicHomePageRoot} from './components';

function App() {
  return (
    <PublicHomePageRoot />
  );
}

export default App;
