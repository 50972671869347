import React from 'react';
import * as Sc from './styled';
import { motion } from 'framer-motion';
import logoBg from '../../../images/page_1_bg_tl.png';

type IProps = {}

export const Page10 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='hstack'>
      <motion.div
        style={{ mixBlendMode: 'luminosity' }}
        className='p-3 my-auto'
      >
        <motion.img
          variants={{
            hidden: { opacity: 0, x: 0 },
            visible: { opacity: 1, x: 0 },
          }}
          initial='hidden'
          whileInView='visible'
          transition={{ duration: 1.5, delay: 0.25 }}
          src={logoBg} style={{ width: '40vw' }} />

      </motion.div>
    </div>
  </Sc.Wrapper>;
};
