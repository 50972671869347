import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import { motion } from 'framer-motion';


type IProps = {}

export const Page7 = (props: IProps) => {

  const steps = [
    {
      bgColor: '#8FFF00',
      label: 'Set up',
      price: '250€',
      priceStroke: '500€',
      duration: 'Woche 0-4',
      steps: [
        'Installation QR Schild',
        'Installation ID Plaketten',
        'Spielplätze inkl Spielgeräte anlegen',
      ],
    }, {
      bgColor: '#FFC700',
      label: 'Kick Off',
      price: '85€',
      priceStroke: '190€',
      duration: 'Woche 4-6',
      steps: [
        'Onboarding Personal',
        'Testlauf',
        'Ggf. Kommunikation an Bürger / Presse',
      ],
    }, {
      bgColor: '#FFC700',
      label: 'Projekt',
      duration: '12 Monate',
      steps: [
        'Arbeiten in der Cloud',
        'Daten sammeln',
        'Feedback verarbeiten',
        'Optimieren',
        'Auswerten',
      ],
    }, {
      bgColor: 'linear-gradient(-90deg, rgba(85, 114, 187, 0) 0%, #5572BB 100%)',
      color: '#5572BB',
      label: 'Service Plan',
      price: '50/100/150€',
      duration: '12 Monate',
      steps: [
        'Cloud',
        'Inspektion',
        'Wartung',
        'Erweiterung (Grünpflege, Digitaler Mülleimer)',
      ],
    },
  ];

  return <Sc.Wrapper>
    <div className='container p-5'>
      <div className='fs-big mb-5'>Ablauf</div>
      <div className='d-flex'>
        {steps.map((step, index) => (
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 100 },
              visible: { opacity: 1, x: 0 },
            }}
            initial='hidden'
            whileInView='visible'
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30,
              duration: 0.5,
              delay: index * 0.5 + 0.5,
            }}

            key={index} style={{ width: index === 2 ? '60%' : 'auto' }}>
            <div className='fs-5 mb-2'>{step.label}</div>
            <div className='fs-2 hstack gap-3' style={{ color: step.color ?? step.bgColor, minHeight: '2em' }}>
              {step.priceStroke && (
                <span className='text-decoration-line-through'>
                  {step.priceStroke}
                </span>
              )}
              {step.price && (
                <span>
                  {step.price}
                </span>
              )}
            </div>
            <div
              className='p-4'
              style={{ borderRadius: '200px', background: step.bgColor, color: '#1C1C1C' }}>
              {step.duration}
            </div>
            <div className='pt-5'>
              <ul>
                {step.steps.map((item, index) => <li key={index}>{item}</li>)}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};