import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';
import img1 from '../../../images/mobile/page_2_1.png';
import img2 from '../../../images/mobile/page_2_2.png';
import img3 from '../../../images/mobile/page_2_3.png';
import img4 from '../../../images/mobile/page_2_4.png';
import { motion } from 'framer-motion';

const damp = 20;
const stiff = 360;
const speed = 0.3;

type IProps = {}

export const Page2 = (props: IProps) => {

  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />
    <div className='d-flex flex-column w-100 img-wrapper'>

      <h1 className={'fs-big mb-5 container'}>Vor Wadiki Cloud</h1>
      <div className='ms-auto mb-3'>
        <div className='mb-3'>
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial='hidden'
            whileInView='visible'
            transition={{
              type: 'spring',
              stiffness: stiff,
              damping: damp,
              duration: speed,
              delay: speed * 1,
            }}
            src={img1}
          />
        </div>
        <div>
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial='hidden'
            whileInView='visible'
            transition={{
              type: 'spring',
              stiffness: stiff,
              damping: damp,
              duration: speed,
              delay: speed * 2,
            }}
            src={img2}
          />
        </div>
      </div>
      <div className='me-auto mb-3'>
        <motion.img
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          initial='hidden'
          whileInView='visible'
          transition={{
            type: 'spring',
            stiffness: stiff,
            damping: damp,
            duration: speed,
            delay: speed * 3,
          }}
          src={img3} />
      </div>
      <div className='ms-auto'>
        <motion.img
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          initial='hidden'
          whileInView='visible'
          transition={{
            type: 'spring',
            stiffness: stiff,
            damping: damp,
            duration: speed,
            delay: speed * 4,
          }}
          src={img4} />
      </div>
    </div>

  </Sc.Wrapper>;
};