import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';
import qr from '../../../images/qr-demo-wadiki-cloud-qr-703R-blue.png';
import imgL from '../../../images/page_1_bg_tl.png';
import { motion } from 'framer-motion';


type IProps = {}

export const Page4 = (props: IProps) => {

  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />
    <motion.div
      variants={{
        hidden: { y: '50vh' },
        visible: { y: 0 },
      }}
      initial='hidden'
      whileInView='visible'
      transition={{
        type: 'easeIn',
        duration: 0.5,
        delay: 0.25,
      }}

      className='bg-yellow-blue d-flex flex-column'
      style={{
        borderRadius: 10,
        paddingTop: 25,
        position: 'absolute',
        top: '15vh',
        left: '8%',
        right: '8%',
        height: '80vh',
      }}
    >
      <div className='d-flex px-3'>
        <div className='col-7'>
          <h1 className={'fs-big mb-5'} style={{ fontSize: '2em' }}>Hallo!</h1>
          <div className='small lh-1'>
            Das ist die Wadiki<br />
            Cloud. Scannen Sie,<br />
            um Spielplatzschäden<br />
            zu melden.
          </div>
        </div>
        <div className='col-5 text-end'>
          <div className='small lh-1 mb-3'>
            Die einzigartige<br />
            Nummer dieses<br />
            Spielplatzes ist
          </div>
          <h1 className={'fs-big mb-3'} style={{ fontSize: '2em' }}>703R</h1>
          <div>
            <img src={qr} className='w-100' alt={'Wadiki Cloud QR-Code'}/>
          </div>
        </div>
      </div>
      <div className='text-center'
           style={{
             height: '100%',
             background: 'linear-gradient(180deg, #FFC700 15.2%, #1C1C1C 90.2%)',

           }}
      >
        <img src={imgL} style={{ maxWidth: '50vw', marginTop: 100 }}  alt={''}/>
      </div>
    </motion.div>


    <div className='footer'>
      <div
        className='ms-auto wadiki-pill bg-blue text-center position-relative'
        style={{
          width: '100%',
          borderRadius: 0,
        }}
      >
        Zugang zur Wadiki Cloud
      </div>
    </div>
  </Sc.Wrapper>;
};