import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import { motion } from 'framer-motion';


type IProps = {}

export const Page2 = (props: IProps) => {

  const items = [
    'Verhältnis Fläche / Mitarbeiter',
    'Rechtssichere Zertifizierung d. MA',
    'Rechtssichere Dokumentation',
    'Haftungsrisiko',
    'Bestandsschutz',
    'Wartungsstau',
    'Blindflug durch den Haushalt',
  ];
  return <Sc.Wrapper>
    <div className='container p-5'>
      <motion.div
        variants={{
          hidden: { opacity: 0.1, scale: 0.2 },
          visible: { opacity: 1, scale: 1 },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{ type: 'spring', duration: 0.70, delay: 0.2 }}
        className='fs-big mb-5'
      >
        Das Problem
      </motion.div>
      <div className='fs-4 mt-5'>
        <ul>
          {items.map((item, index) => (
            <motion.li
              key={index}
              variants={{
                hidden: { opacity: 0.1, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              initial='hidden'
              whileInView='visible'
              transition={{ type: 'spring', duration: 0.30, delay: index * 0.6 + 0.4 }}
            >
              {item}
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};