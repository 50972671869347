import React from 'react';
import * as Sc from './styled';
import logoBg from '../../../images/page_1_bg_tl.png';
import { motion } from 'framer-motion';

type IProps = {}

export const Page11 = (props: IProps) => {


  return <Sc.Wrapper>
    <motion.div

      style={{ mixBlendMode: 'luminosity' }}
      className='p-3'
    >
      <motion.img
        variants={{
          hidden: { opacity: 0, x: 0 },
          visible: { opacity: 1, x: 0 },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{ duration: 1.5, delay: 0.25 }}
        src={logoBg} style={{ maxHeight: '10vh' }} />
    </motion.div>
  </Sc.Wrapper>;
};