import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 133px;
  height: 42px;
  position: absolute;
  z-index: 100;

  @media (max-width: 600px) {
    zoom: 0.5
  }

`;
