import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';


type IProps = {}

export const Page5 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='container p-5'>
      <div className='fs-big mb-5'>Warum Wadiki?</div>
      <div className='fs-4 mt-5'>
        <div>Erfahrener Spielplatzprüfer nach DIN EN 1176.</div>
        <div>Zertifizierter Wartungspartner der Stadt Leipzig.</div>
        <div className='hstack gap-3 mt-5'>
          <div>
            <ul>
              <li>Montagepartner sämtlicher Gerätehersteller</li>
              <li>Innovationsdrang</li>
              <li>Leistungsorientiert</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Hohe Expertise durch Kommunalübergreifende Betreuung</li>
              <li>100% Spielplatz</li>
              <li>Steigerung der Zufriedenheit von Bürgern</li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <BgLogoLayer theme={'light'} />
  </Sc.Wrapper>;
};