import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import logo from '../../../images/logo_small.png';
import logoBg from '../../../images/page_1_bg_tl.png';
import qr from '../../../images/qr-demo-wadiki-cloud-qr-703R-blue.png';

type IProps = {}

export const Page10 = (props: IProps) => {


  return <Sc.Wrapper>
    <div
      className='mt-auto overflow-hidden'
      style={{
        background: '#fce701',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        maxWidth: 500,
        zIndex: 200,
      }}
    >
      <div
        style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)' }}
      >
        <div className='d-flex p-4'>
          <div style={{ width: '64%' }} className='me-5'>
            <div className='mb-4' style={{ fontSize: '4.5rem' }}>Hallo!</div>
            <div className='fs-3 mb-5 lh-1'>
              Das ist die Wadiki<br />Cloud. Scannen Sie,<br />um Spielplatzschäden<br />zu melden.
            </div>
            <div>
              <img src={logo} style={{ width: '30%' }} alt={'Wadiki Cloud Logo'}/>
            </div>
          </div>
          <div className='vstack align-items-end'>
            <div className='fs-5 text-end lh-1 mb-1 mt-3'>Die einzigartige<br />Nummer dieses<br />Spielplatzes ist
            </div>
            <div className='mb-1' style={{ fontSize: '4.5rem' }}>703R</div>
            <img src={qr} style={{ width: '85%' }} alt={'Wadiki Cloud QR-Code'}/>
          </div>
        </div>
      </div>
      <div className='overflow-hidden pt-5 text-center'
           style={{
             background: 'linear-gradient(180deg, #fce701 0%, #806503 200%)',
           }}
      >
        <img src={logoBg} style={{ marginTop: 50, marginBottom: -50, width: '50%' }} alt={''}/>
      </div>
    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};