import React from 'react';
import * as Sc from './styled';
import bg from '../../../../images/logo_small.png';
import bgLight from '../../../../images/logo_small_light.png';


const topStyle = {
  top: '6%',
  left: '6%',
};

const bottomStyle = {
  bottom: '6%',
  right: '6%',
};

export const BgLogoLayer = ({ theme = 'dark', position = 'bottom' }) => {

  const posStyle = position === 'bottom' ? bottomStyle : topStyle;
  return <Sc.Wrapper style={posStyle}
  >
    <img
      src={theme === 'dark' ? bgLight : bg}
      style={{ opacity: 0.2 }}
      alt={'Wadiki Cloud Logo'}
    />
  </Sc.Wrapper>;
};