import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';

type IProps = {}

export const Page7 = (props: IProps) => {

  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />

    <div className='d-flex flex-column w-100 container'>
      <div className={'fs-big mb-5'}>
        Auch nach<br />
        erfolgreicher<br />
        Instandsetzung<br />
        Überblick zu allen<br />
        Ereignissen, Dank<br />
        revisionssicherer<br />
        Archivierung<br />
      </div>
      <div
        className='me-auto wadiki-pill bg-yellow mb-5'
        style={{ transform: 'translate(-3em,0)' }}>
        Instandsetzung
      </div>

    </div>
    <div className='footer'>
      <div
        className='ms-auto wadiki-pill bg-blue text-center'
        style={{
          width: '100%',
          borderRadius: 0,
        }}
      >
        Zugang zur Wadiki Cloud
      </div>
    </div>
  </Sc.Wrapper>;
};
