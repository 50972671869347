import React from 'react';
import * as Sc from './styled';
import imgL from '../../../images/page_1_bg_tl.png';
import imgR from '../../../images/christian-veit.png';
import { motion } from 'framer-motion';

type IProps = {}

export const Page1 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='container h-100'>
      <div className='d-flex h-100 align-items-center'>
        <div style={{marginRight:-20}}>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 125 },
              visible: { opacity: 1, y: 0 },
            }}
            initial='hidden'
            animate='visible'
            transition={{ duration: 0.25, delay: 0.25 }}
          >
            <img src={imgL} style={{ width:'25vh' }} alt={'Wadiki Cloud Logo'}/>
          </motion.div>

          <div style={{fontSize:'5vw',fontWeight:600,lineHeight:1, margin:'10vh 0'}}>
            Spielplatz<br/>
            Management<br/>
            System
          </div>

          <div className='fs-5'>
            <div>Christian Veit</div>
            <div>CEO - WADIKI Cloud</div>
          </div>


        </div>


        <div className='h-100'  style={{marginRight:-40}}>
          <motion.img
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial='hidden'
            animate='visible'
            transition={{ duration: 0.55, delay: 0.5 }}
            src={imgR}
            style={{ maxHeight: '80vh', marginTop:'20vh'}} />
        </div>
          <div className='fw-bold' style={{width:400,flexGrow:1,fontSize:'2vw'}} >
            “Mir wurde klar, dass eine Kombination aus digitalen Anwendungen und Diensten die Lösung sein könnte”
        </div>
      </div>
    </div>
  </Sc.Wrapper>;
};