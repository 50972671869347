import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';


type IProps = {}

export const Page8 = (props: IProps) => {

  const list = [
    'Gemeinsames Pilotprojekt',
    'Ganzheitliche Betreuung inkl. Digitalisierung',
    'Kein Aufwand',
    'Kein Risiko',
    'Freie Kapazitäten',
    'Sofortige Rechtssicherheit',
    'Abrechnung monatlich über Dienstleistungsvertrag',
    'Gute PR',
  ];
  return <Sc.Wrapper>
    <div className='container p-5'>
      <div className='fs-big mb-5'>Benefits</div>
      <div className='d-flex'>
        <ul>
          {list.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      </div>
    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};