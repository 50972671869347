import styled from 'styled-components';
import bg from '../../../images/mobile/bg_dunkelheit.png';

export const Wrapper = styled.div`
  background: #1C1C1C;
  background-image: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;

  .glow {
    background: linear-gradient(220deg, white, transparent);
    -webkit-background-clip: text;
    color: transparent;
  }

`;