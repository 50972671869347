import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../../pages/components/BgLogoLayer';
import { motion } from 'framer-motion';

type IProps = {}

const damp = 20;
const stiff = 360;
const speed = 0.3;

export const Page3 = (props: IProps) => {

  return <Sc.Wrapper>
    <BgLogoLayer theme={'dark'} position='top' />
    <div className='d-flex flex-column w-100 container'>
      <h1 className={'fs-big mb-5'}>
        Es ist einfach!
      </h1>
      <div className={'fs-big mb-5'}>
        Jeder<br />
        Spielplatzbesucher<br />
        kann Schäden und<br />
        Gefahren melden
      </div>
      <motion.div
        variants={{
          hidden: { opacity: 0, scale: 0 },
          visible: { opacity: 1, scale: 1 },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{
          type: 'spring',
          stiffness: stiff,
          damping: damp,
          duration: speed,
          delay: speed * 1,
        }}
        className='wadiki-pill text-bg-light mb-5'
      >
        Melde Probleme über QR Code
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: '5em' },
          visible: { opacity: 1, x: '3em' },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{
          type: 'spring',
          stiffness: stiff,
          damping: damp,
          duration: speed,
          delay: speed * 3,
        }}
        className='ms-auto wadiki-pill bg-yellow mb-5'
        style={{ transform: 'translate(3em,0)' }}
      >
        Wadiki reagiert
      </motion.div>

    </div>
    <div className='footer'>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: '5em' },
          visible: { opacity: 1, x: 0 },
        }}
        initial='hidden'
        whileInView='visible'
        transition={{
          type: 'easeIn',
          duration: speed,
          delay: speed * 2,
        }}
        className='ms-auto wadiki-pill bg-blue text-start'
        style={{
          width: '85%',
          borderEndEndRadius: 0,
          borderStartEndRadius: 0,
        }}
      >
        Zugang zur Wadiki Cloud
      </motion.div>
    </div>
  </Sc.Wrapper>;
};
