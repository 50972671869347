import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import { motion } from 'framer-motion';


type IProps = {}

export const Page3 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='container p-5'>
      <motion.div
        className='fs-big' style={{ color: '#585CB9' }}
      >
        Was ist
      </motion.div>
      <div className='fs-big mb-5 hstack gap-3' style={{ color: '#FFF' }}>
        <div>
          Wadiki Cloud
        </div>
        <motion.div
          variants={{
            hidden: { scale: 0.2 },
            visible: { scale: 1.4 },
          }}
          initial='hidden'
          whileInView='visible'
          transition={{
            type: 'spring',
            stiffness: 1000,
            damping: 10,
            duration: 2,
            delay: 0.2,
          }}
          style={{ color: '#585CB9' }}
        >
          ?
        </motion.div>
      </div>
      <div className='p-5'></div>
      <div className='fs-5 mt-5' style={{ color: '#FFFFFF', opacity: 0.4 }}>
        <div>Die Kombination aus Anwendungsbezogener</div>
        <div>Software mit regionaler Dienstleistung</div>
      </div>
    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};