import styled from 'styled-components';


export const Wrapper = styled.div`

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  height: 100%;

  display: flex;
  flex-direction: row;

  background: #1C1C1C;

  font-size: 2.4vh;

  .footer {
    position: absolute;
    top: 82%;
    bottom: 0;
    right: 0;
    left: 0;
    background: #1C1C1C;
    align-items: start;
  }

  .bg-yellow {
    background: #FFC700;
    color: #1C1C1C;
  }

  .bg-yellow-blue {
    background: #FFC700;
    color: #323467;
  }

  .bg-green {
    background: #8FFF00;
    color: #1C1C1C;
  }

  .bg-blue {
    background: #323467;
    color: #FFF;
  }

  .wadiki-pill {
    padding: 1em 2em;
    border-radius: 2em;
  }

  section {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
    overflow: hidden;

    .container {
      padding: 6%;
    }
  }


  .fs-big {
    font-size: 4vh;
    line-height: 1em;
  }

  .progress {
    z-index: 300;
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    height: 5px;
    background: #FFC700;
  }

  .page-container {
    width: 100vw;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .img-wrapper img {

      height: 10vh;
    }

    > div {
      flex: 1 auto;
      display: flex;
      align-self: stretch;
      align-items: start;
      padding-top: 12vh;
      justify-content: center;
      position: relative;
    }
  }

`;