import React, { useEffect, useState } from 'react';
import { Start } from './pages/Start';
import { MobileStart } from './mobile/Start';

export const PublicHomePageRoot = () => {
  const [isMobile, setMobile] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobile(window.innerHeight > window.innerWidth);
    });
  }, []);

  return isMobile ? <MobileStart /> : <Start />;
};