import styled from 'styled-components';


export const Wrapper = styled.div`
  background: #1C1C1C;
  color: #FFFFFF;


  .img-wrapper img {

    height: 15vh;
  }
`;