import React from 'react';
import * as Sc from './styled';
import { BgLogoLayer } from '../components/BgLogoLayer';
import qrCode from '../../../images/qr-wadiki-cloud.png';


type IProps = {}

export const Page9 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='container p-0'>
      <div className='hstack gap-3 w-100'>
        <div className='col-9'>
          <div className='fs-big mb-5'>Sehen Sie,<br />wie einfach<br />es ist</div>
          <div className='d-flex fs-4 col-8'>
            Scannen Sie den QR-Code und sehen Sie, wie einfach und unterstützend Wadiki Cloud für Sie funktioniert
          </div>
        </div>
        <div className='col-3'>
          <img src={qrCode} className='w-100' alt={'Wadiki Cloud QR-Code'}/>
        </div>
      </div>

    </div>
    <BgLogoLayer theme={'dark'} />
  </Sc.Wrapper>;
};