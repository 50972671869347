import React from 'react';
import * as Sc from './styled';
import imgL from '../../../images/page_1_bg_tl.png';
import { motion } from 'framer-motion';

type IProps = {}

export const Page1 = (props: IProps) => {

  return <Sc.Wrapper>
    <div className='hstack'>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial='hidden'
        animate='visible'
        transition={{ duration: 0.25, delay: 0.25 }}
        className='mb-5'>
        <img src={imgL} style={{ width: '40vw' }} alt={'Wadiki Cloud - Logo'} />
      </motion.div>
    </div>
  </Sc.Wrapper>;
};