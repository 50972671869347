import styled from 'styled-components';
import bg from '../../../images/bg_playground_2.png';


export const Wrapper = styled.div`
  background: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #323467;
  padding: 0 15px;
`;